import React from 'react';

import ArrowLeft from '../../images/icon-angle-left.svg';
import ArrowRight from '../../images/icon-angle-right.svg';
import ArrowLeftDark from '../../images/icon-angle-left-dark.svg';
import ArrowRightDark from '../../images/icon-angle-right-dark.svg';

export default function SliderArrow(props) {
  const { dir, onClick, label, carousel } = props;
  const arrowIcon = () => {
    if (dir === 'prev') {
      if (carousel) {
        return ArrowLeftDark;
      }
      return ArrowLeft;
    }

    if (carousel) {
      return ArrowRightDark;
    }

    return ArrowRight;
  };
  return (
    <button
      type="button"
      className={`hidden arrow ${dir}`}
      onClick={onClick}
      aria-label={label}
      style={{
        ...arrowStyles,
        backgroundImage: `url(${arrowIcon()})`,
      }}
    />
  );
}

const arrowStyles = {
  position: `absolute`,
  top: 0,
  bottom: 0,
  width: `90px`,
  zIndex: 20,
  transition: `all 0.3s ease`,
  // opacity: 0,
  backgroundRepeat: `no-repeat`,
  backgroundPosition: `50% 50%`,
  backgroundSize: `8px`,
};
